<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
    <div class="max-w-md">
      <div
        v-if="!poiLoading"
        ref="poiTableContainerRef"
      >
        <app-section-title
          :title="t('ttmt.institutions.index.sections.validated_claims')"
          class="mb-4"
        />

        <!-- Institutions list -->
        <template
          v-if="pointsOfInterest.length > 0"
        >
          <app-card-item
            v-for="(pointOfInterest, i) in pointsOfInterest"
            :key="i"
            class="my-4 p-4"
          >
            <!-- Institution details -->
            <point-of-interest-card
              v-bind="pointOfInterest"
            />

            <!-- QR Code -->
            <institutions-qr-code
              :poi="pointOfInterest"
            />
          </app-card-item>
        </template>

        <p
          v-else
          class="mt-6"
        >
          {{ t('ttmt.extranet.institution.messages.no_records') }}
        </p>
      </div>

      <!-- Empty cards loading -->
      <template
        v-else
      >
        <point-of-interest-card-empty
          v-for="i in 3"
          :key="i"
          class="my-4"
        />
      </template>

      <!-- Pagination -->
      <app-pagination
        :links="poiLinks"
        :change-page-callback="handlePoiChangePage"
        :element-to-scroll-to="poiTableContainerRef"
        class="mt-5"
      />
    </div>

    <!-- Second column -->
    <div />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { fetchOwnedPointsOfInterest } from '@shared/http/api'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppPagination from '@extranet/components/ui/AppPagination.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import InstitutionsQrCode from '@extranet/components/institutions/InstitutionsQrCode.vue'
import PointOfInterestCard from '@extranet/components/resources/point_of_interest/PointOfInterestCard.vue'
import PointOfInterestCardEmpty from '@extranet/components/resources/point_of_interest/PointOfInterestCardEmpty.vue'

const { t } = useI18n()

// ---------- POINTS OF INTEREST ----------

const poiNextPageFetching = ref(false)
const pointsOfInterest = ref([])
const poiLoading = ref(true)

function fetchPois(nextPage = false) {
  if (nextPage === false) {
    poiLoading.value = true
  }

  poiNextPageFetching.value = nextPage

  const params = {
    'page': poiPage.value,
  }

  fetchOwnedPointsOfInterest(params)
    .then((response) => {
      pointsOfInterest.value = response.data.data?.map((pointOfInterest) => ({
        resource: pointOfInterest,
      })) ?? []

      // Pagination links
      poiLinks.value = response.data.meta?.links ?? []
    })
    .finally(() => {
      poiNextPageFetching.value = false
      poiLoading.value = false
    })
}

// ---------- POI PAGINATION ----------

const poiTableContainerRef = ref(null)
const poiLinks = ref([])
const poiPage = ref(1)

function handlePoiChangePage(value) {
  poiPage.value = value
  fetchPois()
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchPois()
})
</script>
